var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('h3', [_vm._v("Talent Dashboard")]), _c('div', {
    staticClass: "py-24"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/amico.png"),
      "alt": ""
    }
  }), _c('p', [_vm._v("Access your dashboard to talent information system here")]), _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "inline-block w-80 mt-6 mb-16"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Open Talent Dashboard",
      "size": "full"
    },
    on: {
      "action": function action($event) {
        return _vm.talent();
      }
    }
  })], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }